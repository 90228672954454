import React, { useState, useEffect } from "react";
import add from "../../../../assets/images/add.png";
import { useHistory } from "react-router-dom";
import $ from "jquery";
import Api, { api_url, testApiurl, testApiurl1 } from "../../../../apis/apis";
import Notifications from "../../../../notifications/notifications";

function Ads() {
  let history = useHistory();

  let [ads, setAds] = useState(null);

  useEffect(async () => {
    let res = await Api.allAdsAction();

    if (res.status == "200") {
      setAds(res.data);
    }

    //initialize datatable
    $(document).ready(function () {
      setTimeout(function () {
        $("#example").DataTable();
      }, 500);
    });
  }, []);

  const isImage = (url) => {
    return /\.(jpg|jpeg|png||PNG|webp|avif|gif|svg)$/.test(url);
  };

  async function deleteAds(id) {
    let data = {
      id: id,
    };

    let res = await Api.deleteAdsAction(data);

    if (res.status == "200") {
      await Notifications.successMsg(res.message);
      await refreshRecord();
    }
  }

  async function refreshRecord() {
    let res = await Api.allAdsAction();

    if (res.status == "200") {
      setAds(res.data);
    }
  }

  return (
    <>
      <div className="driver-head">
        <h3 className="dashboard-head-title">Ads</h3>
        <div>
          <div className="nav-buttons">
            <button
              onClick={() => history.push("/add-ads")}
              style={{ background: "#FF7B00", marginRight: "15px" }}
            >
              <img src={add} alt="" /> Add Ads
            </button>
          </div>
        </div>
      </div>
      <div className="dashboard-table-wrapper_ p-3">
        <table
          id="example"
          className="table table-hover table-bordered"
          style={{ overFlow: "auto" }}
        >
          <thead>
            <tr>
              <th>Title</th>
              <th>Image</th>
              <th>Type</th>
              <th>Position</th>
              {/* <th>Edit</th> */}
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            {ads?.map((itm, i) => (
              <tr key={i}>
                <td>{itm.title}</td>
                <td>
                  {isImage(itm.imageUrl) ? (
                    <img
                      style={{
                        objectFit: "cover",
                        width: "100px",
                        height: "100px",
                      }}
                      src={`${testApiurl1}${itm.imageUrl}`}
                      alt=""
                    />
                  ) : (
                    <video width="120" height="120" controls>
                      <source src={`${testApiurl1}${itm.imageUrl}`} />
                    </video>
                  )}
                </td>
                <td>{itm.type}</td>
                <td>{itm?.position || 'N/A'}</td>
                {/* <td>
                  <i
                    onClick={() => {
                      sessionStorage.setItem("ceid", itm.id);
                      history.push("/edit-category");
                    }}
                    className={"fas fa-pen"}
                    style={{ cursor: "pointer", color: "green" }}
                  ></i>
                </td> */}
                <td>
                  <i
                    onClick={() => {
                      deleteAds(itm.id);
                    }}
                    className={"fas fa-trash"}
                    style={{ cursor: "pointer", color: "red" }}
                  ></i>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default Ads;
