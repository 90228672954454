import React, { useState, useEffect } from 'react'
import add from '../../../../assets/images/add.png'
import { useHistory } from 'react-router-dom';
import $ from 'jquery';
import Api from "../../../../apis/apis";
import Notifications from "../../../../notifications/notifications";



function Properties() {

    let history = useHistory();

    let [property, setProperty] = useState(null);

    useEffect(async () => {

        let res=await Api.allProperties();
        if (res.status == '200')
        {
            setProperty(res.data);
        }

        //initialize datatable
        $(document).ready(function () {
            setTimeout(function () {
                $('#example').DataTable();
            }, 500);
        });
    }, [])


    async function Delete(id) {
        let data = {
            id: id
        }

        let res = await Api.deleteProperty(data);
        console.log(res);

        if (res.status == '200') {
            await Notifications.successMsg(res.message);
            await refreshRecord();
        }
    }

    async function refreshRecord() {
        let res=await Api.allProperties();
        if (res.status == '200')
        {
            setProperty(res.data);
        }
    }

    function editCleaner(id) {
        console.log(id)
        sessionStorage.setItem('cleanerId', id);
        history.push('/edit-cleaner')
    }



    return (
        <>
            <div className='driver-head'>
                <h3 className='dashboard-head-title'>Properties</h3>
                <div>
                    <div className='nav-buttons'>
                        {/*<button onClick={() => history.push('/add-property')} style={{ background: '#FF7B00', marginRight: '15px' }}><img src={add} alt="" />  Add Property</button>*/}
                    </div>
                </div>
            </div>
            <div className='dashboard-table-wrapper_ p-3' >

                <table id="example" className="table table-hover table-bordered" style={{ overFlow: "auto" }}>
                    <thead>
                    <tr>
                        <th>Id</th>
                        <th>User</th>
                        <th>Name</th>
                        <th>Description</th>

                        <th>Category</th>
                        <th>City</th>
                        <th>Rooms</th>
                        <th>Bathrooms</th>

                        <th>Parking</th>
                        <th>Furnished</th>
                        <th>Price</th>
                        <th>Area</th>

                        <th>Type</th>
                        <th>Annual Increment</th>
                        <th>House Address</th>
                        <th>House Address Picture</th>

                        <th>House Pictures</th>
                        <th>Owner Name</th>
                        <th>Owner Email</th>
                        <th>Owner Phone</th>
                        <th>Owner Address</th>
                        <th>Delete</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        property?.map((itm,i)=>(
                            <tr>
                                <td>{itm.id}</td>
                                <td>{itm.userId}</td>
                                <td>{itm.name}</td>
                                <td>{itm.desc}</td>

                                <td>{itm.category}</td>
                                <td>{itm.city}</td>
                                <td>{itm.rooms}</td>
                                <td>{itm.bathrooms}</td>

                                <td>{itm.parking}</td>
                                <td>{(itm.furnished == 'yes')?'Yes':'No'}</td>
                                <td>{itm.price}</td>
                                <td>{itm.area}</td>

                                <td>{itm.type}</td>
                                <td>{itm.annualIncrement?itm.annualIncrement:'---'}</td>
                                <td>{itm.address}</td>
                                <td><a href={itm.houseAddressPicUrl} target='_blank'>picture</a></td>

                                <td>
                                    <a href={itm.imageUrl1} target='_blank'>picture 1</a><br/>
                                    <a href={itm.imageUrl2} target='_blank'>picture 2</a><br/>
                                    <a href={itm.imageUrl3} target='_blank'>picture 3</a><br/>
                                    <a href={itm.imageUrl4} target='_blank'>picture 4</a><br/>
                                    <a href={itm.imageUrl5} target='_blank'>picture 5</a><br/>
                                </td>
                                <td>{itm.ownerName}</td>
                                <td>{itm.ownerEmail}</td>
                                <td>{itm.ownerPhone}</td>
                                <td>{itm.ownerAddress}</td>
                                <td><i onClick={async ()=>{await Delete(itm.id)}} className={'fas fa-trash'} style={{cursor: "pointer",color: "red"}}></i></td>
                            </tr>
                        ))
                    }
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default Properties