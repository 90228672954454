import React, {useEffect, useState} from 'react'
import Api from "../../../../../apis/apis";
import Notifications from "../../../../../notifications/notifications";
import {useHistory} from 'react-router-dom';

function AddState() {

    const history=useHistory();

    let [name,setName]=useState(null);



    function resetForm(){
        setName("");
    }

    async function submitForm(e) {
        e.preventDefault();

        let data={
            name: name,
        }

        let res=await Api.addState(data);


        if (res.status == "200")
        {
            await Notifications.successMsg(res.message);
            history.push('/states');
        }
        else
        {
            await Notifications.errorMsg(res.message);
        }
        resetForm();
    }

    return (
        <>
            <h3 className='dashboard-head-title'>Add Property</h3>
            <form onSubmit={submitForm}>
                <div className=' load-form'>
                    <div className=' load-input-wrapper'>
                        <div className=' load-input'>
                            <label>Name</label>
                            <input value={name} onChange={(e)=>{setName(e.target.value)}} required type={'text'} placeholder="Enter Name" className='form-input_ input' />
                        </div>
                    </div>
                    <div className='form-buttons'>
                        <button type={'submit'} className='button-primary'>Save Changes</button>
                        {/*<button className='button-primary btn-cancel'>Cancel</button>*/}
                    </div>
                </div>
            </form>
        </>
    )
}

export default AddState