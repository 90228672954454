import React,{useState,useEffect} from 'react'
import add from '../../../../assets/images/add.png'
import {useHistory} from 'react-router-dom';
import $ from 'jquery';
import Api from "../../../../apis/apis";
import Notifications from "../../../../notifications/notifications";



function Category() {

    let history=useHistory();

    let [category,setCategory]=useState(null);

    useEffect(async ()=>{

        let res=await Api.allCategory();

        if (res.status == '200')
        {
            setCategory(res.data);
        }

        //initialize datatable
        $(document).ready(function () {
            setTimeout(function(){
                $('#example').DataTable();
            } ,500);
        });
    },[])


    async function deleteSubAdmin(id) {

        let data={
            id: id
        }

        let res= await Api.deleteCategory(data);

        if (res.status == '200')
        {
            await Notifications.successMsg(res.message);
            await refreshRecord();
        }
    }

    async function refreshRecord() {
        let res=await Api.allCategory();

        if (res.status == '200')
        {
            setCategory(res.data);
        }
    }


    return (
        <>
            <div className='driver-head'>
                <h3 className='dashboard-head-title'>Categories</h3>
                <div>
                    <div className='nav-buttons'>
                        <button onClick={()=>history.push('/add-category')} style={{ background: '#FF7B00', marginRight: '15px' }}><img src={add} alt="" />  Add Category</button>
                    </div>
                </div>
            </div>
            <div className='dashboard-table-wrapper_ p-3' >

                <table id="example" className="table table-hover table-bordered" style={{overFlow:"auto"}}>
                    <thead>
                    <tr>
                        <th>Id</th>
                        <th>Name</th>
                        <th>Type</th>
                        <th>Edit</th>
                        <th>Delete</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        category?.map((itm,i)=>(
                            <tr key={i}>
                                <td>{itm.id}</td>
                                <td>{itm.name}</td>
                                <td>{itm.type}</td>
                                <td><i onClick={()=>{sessionStorage.setItem('ceid',itm.id);history.push('/edit-category')}} className={'fas fa-pen'} style={{cursor: "pointer",color: "green"}}></i></td>
                                <td><i onClick={()=>{deleteSubAdmin(itm.id)}} className={'fas fa-trash'} style={{cursor: "pointer",color: "red"}}></i></td>
                            </tr>
                        ))
                    }
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default Category