import React, {useEffect, useState} from 'react'
import Api from "../../../../../apis/apis";
import Notifications from "../../../../../notifications/notifications";
import {useHistory} from 'react-router-dom';

function EditAds() {

    const history=useHistory();

    let [category,setCategory]=useState(null);

    let [name,setName]=useState(null);
    let [type,setType]=useState(null);


    useEffect(async ()=>{

        let data={
            id: sessionStorage.getItem('ceid')
        }

        let tempData=await Api.categoryById(data);

        if (tempData.status == '200')
        {
            console.log(tempData.data.name)
            setName(tempData.data.name);
        }



    },[])


    function resetForm(){
        setName("");
        setType("");
    }

    async function submitForm(e) {
        e.preventDefault();

        let data={
            id: sessionStorage.getItem('ceid'),
            name: name,
            type: type
        }

        let res=await Api.updateCategory(data);


        if (res.status == "200")
        {
            await Notifications.successMsg(res.message);
            history.push('/category');
        }
        else
        {
            await Notifications.errorMsg(res.message);
        }
        resetForm();
    }

    return (
        <>
            <h3 className='dashboard-head-title'>Edit Category</h3>
            <form onSubmit={submitForm}>
                <div className=' load-form'>
                    <div className=' load-input-wrapper'>
                        <div className=' load-input'>
                            <label>Name</label>
                            <input value={name} onChange={(e)=>{setName(e.target.value)}}  type={'text'} placeholder="Enter Name" className='form-input_ input' />
                        </div>
                    </div>
                    <div className=' load-input-wrapper'>
                        <div className=' load-input'>
                            <label>Type</label>
                            <select onChange={(e)=>{setType(e.target.value)}} required className='form-input_ input'>
                                <option value={''}>Select</option>
                                <option value={'sell'}>Sell</option>
                                <option value={'rent'}>Rent</option>
                            </select>
                        </div>
                    </div>
                    <div className='form-buttons'>
                        <button type={'submit'} className='button-primary'>Save Changes</button>
                        {/*<button className='button-primary btn-cancel'>Cancel</button>*/}
                    </div>
                </div>
            </form>
        </>
    )
}

export default EditAds