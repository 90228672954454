import React, {useEffect, useState} from 'react'
import Api from "../../../../../apis/apis";
import Notifications from "../../../../../notifications/notifications";
import {useHistory} from 'react-router-dom';

function AddCity() {

    const history=useHistory();

    let [name,setName]=useState(null);
    let [stateID,setStateId]=useState(null);

    let [state, setStates] = useState(null);


    useEffect(async ()=>{

        let res= await Api.allStates();
        if (res.status == '200')
        {
            setStates(res.data);
        }

    },[])



    function resetForm(){
        setName("");
        setStateId("");
    }

    async function submitForm(e) {
        e.preventDefault();

        let data={
            name: name,
            stateId: stateID
        }

        let res=await Api.addCity(data);


        if (res.status == "200")
        {
            await Notifications.successMsg(res.message);
            history.push('/city');
        }
        else
        {
            await Notifications.errorMsg(res.message);
        }
        resetForm();
    }

    return (
        <>
            <h3 className='dashboard-head-title'>Add Property</h3>
            <form onSubmit={submitForm}>
                <div className=' load-form'>
                    <div className=' load-input-wrapper'>
                        <div className=' load-input'>
                            <label>State</label>
                            <select onChange={(e)=>{setStateId(e.target.value)}} required className='form-input_ input'>
                                <option value={'null'}>Select</option>
                                {
                                    state?.map((itm,i)=>(
                                        <option value={itm.id}>{itm.name}</option>
                                    ))
                                }
                            </select>
                            {/*<input value={name}  type={'text'} placeholder="Enter Name" className='form-input_ input' />*/}
                        </div>
                        <div className=' load-input'>
                            <label>Name</label>
                            <input value={name} onChange={(e)=>{setName(e.target.value)}} required type={'text'} placeholder="Enter Name" className='form-input_ input' />
                        </div>
                    </div>
                    <div className='form-buttons'>
                        <button type={'submit'} className='button-primary'>Save Changes</button>
                        {/*<button className='button-primary btn-cancel'>Cancel</button>*/}
                    </div>
                </div>
            </form>
        </>
    )
}

export default AddCity