import React,{useState,useEffect} from 'react'
import add from '../../../../assets/images/add.png'
import {useHistory} from 'react-router-dom';
import $ from "jquery";
import Api from "../../../../apis/apis";
import Notifications from "../../../../notifications/notifications";


function User() {

    let history=useHistory();

    let [users,setUsers]=useState(null);

    useEffect(async ()=>{

        let res=await Api.allUsers();
        if (res.status == '200')
        {
            setUsers(res.data);

        }

        //initialize datatable
        $(document).ready(function () {
            setTimeout(function(){
                $('#example').DataTable();
            } ,500);
        });
    },[])




    async function deleteUser(id) {
        let data={
            id: id
        }

        let res= await Api.deleteUser(data);
        console.log(res);

        if (res.status == '200')
        {
            await Notifications.successMsg(res.message);
            await refreshRecord();
        }
    }

    async function refreshRecord() {
        let res=await Api.allUsers();
        if (res.status == '200')
        {
            setUsers(res.data);

        }
    }

    // function editAffiliate(id) {
    //     console.log(id)
    //     sessionStorage.setItem('affiliateId',id);
    //     history.push('/edit-affiliate')
    // }

    return (
        <>
            <div className='driver-head'>
                <h3 className='dashboard-head-title'>Users</h3>
                <div>
                    {/*<div className='nav-buttons'>*/}
                    {/*    <button onClick={()=>history.push('/add-user')} style={{ background: '#FF7B00', marginRight: '15px' }}><img src={add} alt="" />  Add Users</button>*/}
                    {/*</div>*/}
                </div>
            </div>
            <div className='dashboard-table-wrapper_ p-3'>
                <table id="example" className="table table-hover table-bordered" style={{overFlow:"auto"}}>
                    <thead>
                    <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Delete</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        users?.map((itm,i)=>(
                            <tr key={i}>
                                <td>{itm.id}</td>
                                <td>{itm.name}</td>
                                <td>{itm.email}</td>
                                <td><i onClick={()=>{deleteUser(itm.id)}} className={'fas fa-trash'} style={{cursor: "pointer",color: "red"}}></i></td>
                            </tr>
                        ))
                    }
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default User