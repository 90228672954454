import './dashboard.css'
import React,{useState,useEffect} from 'react'
import $ from 'jquery';
import Api from "../../../../apis/apis";

function Dashboard() {

    let [bookings,setBookings]=useState(null);
    let [counts,setCounts]=useState(null);

    useEffect(async ()=>{

        let res=await Api.getAllBookings();
        if (res.status == '200')
        {
            setBookings(res.data);
        }

        let res1= await Api.getAllAdminCounts();
        if (res1.status == '200')
        {
            setCounts(res1.data);
        }


        //initialize datatable
        $(document).ready(function () {
            setTimeout(function(){
                $('#example').DataTable();
            } ,500);
        });
    },[])

    return (
        <>
            {/*<div style={{marginBottom:"3%"}} className='dashboard-table-wrapper_ p-3'>*/}
            {/*    <div className={'dashboardDiv'}>*/}
            {/*        <div className={'dashboardDivCounter'}>*/}
            {/*            <h4>User <span><span style={{visibility: "hidden"}}>d</span> 3</span></h4>*/}
            {/*        </div>*/}
            {/*        <div className={'dashboardDivCounter'}>*/}
            {/*            <h4>Property <span><span style={{visibility: "hidden"}}>d</span> 3</span></h4>*/}
            {/*        </div>*/}
            {/*        <div className={'dashboardDivCounter'}>*/}
            {/*            <h4>Sub Admin <span><span style={{visibility: "hidden"}}>d</span> 3</span></h4>*/}
            {/*        </div>*/}
            {/*    </div>*/}

            {/*</div>*/}
            {/*<div className='dashboard-table-wrapper_ p-3'>*/}
            {/*    <h3>Latest Bookings</h3>*/}
            {/*    <table id="example" className="table table-hover table-bordered" style={{overFlow:"auto"}}>*/}
            {/*        <thead>*/}
            {/*        <tr>*/}
            {/*            <th>ID</th>*/}
            {/*            <th>Name</th>*/}
            {/*            <th>Email</th>*/}
            {/*        </tr>*/}
            {/*        </thead>*/}
            {/*        <tbody>*/}
            {/*        <tr>*/}
            {/*            <td>1</td>*/}
            {/*            <td>test</td>*/}
            {/*            <td>tes@gmail.com</td>*/}
            {/*        </tr>*/}
            {/*        <tr>*/}
            {/*            <td>2</td>*/}
            {/*            <td>test1</td>*/}
            {/*            <td>tes1@gmail.com</td>*/}
            {/*        </tr>*/}
            {/*        </tbody>*/}
            {/*    </table>*/}
            {/*</div>*/}
            <div style={{ padding: '13px 0' }}></div>
        </>
    )
}

export default Dashboard