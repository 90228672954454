import axios from "axios";
import Notifications from "../notifications/notifications";

const env = process.env;

export const apiUrl = env.REACT_APP_API_URL || "https://backend.ungaveedu.com";

let live_url = `${apiUrl}/`;
let local_url = "http://localhost:4700/";
export let testApiurl = live_url;
export let testApiurl1 = apiUrl;

export let api_url = live_url;

let token = null;

const config = {
  headers: { "content-type": "multipart/form-data" },
};

async function checkLogin() {
  let authToken = sessionStorage.getItem("token");
  if (authToken) {
    token = { headers: { Authorization: `Bearer ${authToken}` } };
  } else {
    window.location.href = "/sign-in";
    await Notifications.errorMsg("You are not seem to be Login, Login First");
  }
}

export default class Api {
  // Admin SignIn
  static adminSignIn = async (data) => {
    try {
      const res = await axios.post(api_url + `admin-login`, data);
      return res.data;
    } catch (error) {
      return error.data;
    }
  };

  // All Sub-Admins
  static allSubAdmins = async () => {
    try {
      const res = await axios.get(api_url + `get-all-sub-admin`);
      return res.data;
    } catch (error) {
      return error.data;
    }
  };

  // Add Sub-Admin
  static addSubAdmin = async (data) => {
    await checkLogin();
    try {
      const res = await axios.post(api_url + `add-sub-admin`, data, token);
      return res.data;
    } catch (error) {
      return error.data;
    }
  };

  // Delete Sub-Admin
  static deleteSubAdmin = async (data) => {
    await checkLogin();
    try {
      const res = await axios.post(api_url + `delete-sub-admin`, data, token);
      return res.data;
    } catch (error) {
      return error.data;
    }
  };

  // Sub-Admin by Id
  static subAdminById = async (data) => {
    await checkLogin();
    try {
      const res = await axios.post(api_url + `sub-admin-by-id`, data, token);
      return res.data;
    } catch (error) {
      return error.data;
    }
  };

  // Sub-Admin by Id
  static updateSubAdmin = async (data) => {
    await checkLogin();
    try {
      const res = await axios.post(api_url + `update-sub-admin`, data, token);
      return res.data;
    } catch (error) {
      return error.data;
    }
  };

  // All users
  static allUsers = async () => {
    try {
      const res = await axios.get(api_url + `get-all-users`);
      return res.data;
    } catch (error) {
      return error.data;
    }
  };

  // Delete User
  static deleteUser = async (data) => {
    await checkLogin();
    try {
      const res = await axios.post(api_url + `delete-user`, data, token);
      return res.data;
    } catch (error) {
      return error.data;
    }
  };

  // Add Category
  static addCategory = async (data) => {
    await checkLogin();
    try {
      const res = await axios.post(api_url + `add-category`, data, token);
      return res.data;
    } catch (error) {
      return error.data;
    }
  };

  // All Category
  static allCategory = async () => {
    try {
      const res = await axios.get(api_url + `get-all-category`);
      return res.data;
    } catch (error) {
      return error.data;
    }
  };

  // Delete Category
  static deleteCategory = async (data) => {
    await checkLogin();
    try {
      const res = await axios.post(api_url + `delete-category`, data, token);
      return res.data;
    } catch (error) {
      return error.data;
    }
  };

  // category by id
  static categoryById = async (data) => {
    await checkLogin();
    try {
      const res = await axios.post(api_url + `category-by-id`, data, token);
      return res.data;
    } catch (error) {
      return error.data;
    }
  };

  // update category
  static updateCategory = async (data) => {
    await checkLogin();
    try {
      const res = await axios.post(api_url + `edit-category`, data, token);
      return res.data;
    } catch (error) {
      return error.data;
    }
  };

  // get all properties
  static allProperties = async () => {
    try {
      const res = await axios.get(api_url + `get-all-property`);
      return res.data;
    } catch (error) {
      return error.data;
    }
  };

  // delete properties
  static deleteProperty = async (data) => {
    await checkLogin();
    try {
      const res = await axios.post(api_url + `delete-property`, data);
      return res.data;
    } catch (error) {
      return error.data;
    }
  };

  // get all chat
  static allChat = async () => {
    try {
      const res = await axios.get(api_url + `all-chat`);
      return res.data;
    } catch (error) {
      return error.data;
    }
  };

  // delete chat
  static deleteChat = async (data) => {
    await checkLogin();
    try {
      const res = await axios.post(api_url + `delete-chat`, data);
      return res.data;
    } catch (error) {
      return error.data;
    }
  };

  // all state
  static allStates = async () => {
    try {
      const res = await axios.get(api_url + `all-state`);
      return res.data;
    } catch (error) {
      return error.data;
    }
  };

  // Add state
  static addState = async (data) => {
    await checkLogin();
    try {
      const res = await axios.post(api_url + `add-state`, data, token);
      return res.data;
    } catch (error) {
      return error.data;
    }
  };

  // delete state
  static deleteState = async (data) => {
    await checkLogin();
    try {
      const res = await axios.post(api_url + `delete-state`, data, token);
      return res.data;
    } catch (error) {
      return error.data;
    }
  };

  // all state
  static allCities = async () => {
    try {
      const res = await axios.get(api_url + `all-cities`);
      return res.data;
    } catch (error) {
      return error.data;
    }
  };

  // Add city
  static addCity = async (data) => {
    await checkLogin();
    try {
      const res = await axios.post(api_url + `add-city`, data, token);
      return res.data;
    } catch (error) {
      return error.data;
    }
  };

  // delete state
  static deleteCity = async (data) => {
    await checkLogin();
    try {
      const res = await axios.post(api_url + `delete-city`, data, token);
      return res.data;
    } catch (error) {
      return error.data;
    }
  };

  static deleteProperty = async (data) => {
    try {
      const res = await axios.post(api_url + `delete-property`, data);
      return res.data;
    } catch (error) {
      return error.data;
    }
  };
  // all trends
  static allTrends = async () => {
    try {
      const res = await axios.get(api_url + `all-trends`);
      return res.data;
    } catch (error) {
      return error.data;
    }
  };

  static addTrend = async (data) => {
    try {
      const res = await axios.post(api_url + `add-trend`, data);
      return res.data;
    } catch (error) {
      return error.data;
    }
  };

  static deleteTrend = async (data) => {
    try {
      const res = await axios.post(api_url + `delete-trend`, data);
      return res.data;
    } catch (error) {
      return error.data;
    }
  };

  // Add Ads
  static addAdsAction = async (data) => {
    await checkLogin();
    try {
      const res = await axios.post(testApiurl + `ads/add`, data, token);
      return res.data;
    } catch (error) {
      return error.data;
    }
  };

  // All Ads
  static allAdsAction = async () => {
    try {
      const res = await axios.get(testApiurl + `ads/list/all`);
      return res.data;
    } catch (error) {
      return error.data;
    }
  };

  // Delete Ads
  static deleteAdsAction = async (data) => {
    await checkLogin();
    try {
      const res = await axios.post(testApiurl + `ads/delete`, data, token);
      return res.data;
    } catch (error) {
      return error.data;
    }
  };
}
