import "./dashboardpanel.css";
import React from "react";
import { Route } from "react-router-dom";
import Protected from "../../protected";
import Header from "../header/header";
import Dashboard from "./dashboard/dashboard";

import Admin from "./admin/admin";
import AddAdmin from "./admin/add/add";
import EditAdmin from "./admin/edit/edit";
import User from "./user/user";
import AddUser from "./user/add/add";
import EditUser from "./user/edit/edit";
import Properties from "./properties/properties";
import AddProperty from "./properties/add/add";
import EditProperty from "./properties/edit/edit";
import Category from "./category/category";
import AddCategory from "./category/add/add";
import EditCategory from "./category/edit/edit";
import Chat from "./chat/chat";
import State from "./states/states";
import AddState from "./states/add/add";
import City from "./cities/cities";
import AddCity from "./cities/add/add";
import Trends from "./trends/trends";
import AddTrend from "./trends/add/add";
import AddAds from "./ads/add/add";
import Ads from "./ads/ads";
import EditAds from "./ads/edit/edit";

function DashboardPanel() {
  return (
    <div className="dashboard">
      <Header />
      <div className="dashboard-wrapper">
        <div className="dashboard-cont">
          {/* Admin Routes*/}
          <Protected path="/dashboard" component={Dashboard} />
          <Protected exact path="/" component={Dashboard} />

          <Protected path="/sub-admin" component={Admin} />
          <Protected path="/add-admin" component={AddAdmin} />
          <Protected path="/edit-admin" component={EditAdmin} />

          <Protected path="/user" component={User} />
          <Protected path="/add-user" component={AddUser} />
          <Protected path="/edit-user" component={EditUser} />

          <Protected path="/properties" component={Properties} />
          <Protected path="/add-property" component={AddProperty} />
          <Protected path="/edit-property" component={EditProperty} />

          <Protected path="/category" component={Category} />
          <Protected path="/add-category" component={AddCategory} />
          <Protected path="/edit-category" component={EditCategory} />

          <Protected path="/chat" component={Chat} />

          <Protected path="/states" component={State} />
          <Protected path="/add-state" component={AddState} />

          <Protected path="/city" component={City} />
          <Protected path="/add-city" component={AddCity} />

          <Protected path="/trends" component={Trends} />
          <Protected path="/add-trend" component={AddTrend} />

          <Protected path="/ads" component={Ads} />
          <Protected path="/add-ads" component={AddAds} />
          <Protected path="/edit-ads" component={EditAds} />
        </div>
      </div>
    </div>
  );
}

export default DashboardPanel;
