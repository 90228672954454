import React, {useEffect, useState} from 'react'
import Api from "../../../../../apis/apis";
import Notifications from "../../../../../notifications/notifications";
import {useHistory} from 'react-router-dom';

function AddTrend() {

    const history=useHistory();

    let [name,setName]=useState(null);
    let [year1,setYear1]=useState(null);
    let [year2,setYear2]=useState(null);
    let [year3,setYear3]=useState(null);
    let [year4,setYear4]=useState(null);
    let [year5,setYear5]=useState(null);
    let [price1,setPrice1]=useState(null);
    let [price2,setPrice2]=useState(null);
    let [price3,setPrice3]=useState(null);
    let [price4,setPrice4]=useState(null);
    let [price5,setPrice5]=useState(null);



    function resetForm(){
        setName("");
        setYear1("");
        setYear2("");
        setYear3("");
        setYear4("");
        setYear5("");
        setPrice1("");
        setPrice2("");
        setPrice3("");
        setPrice4("");
        setPrice5("");
    }

    async function submitForm(e) {
        e.preventDefault();

        let data={
            name: name,
            year1: year1,
            year2: year2,
            year3: year3,
            year4: year4,
            year5: year5,
            price1: price1,
            price2: price2,
            price3: price3,
            price4: price4,
            price5: price5,
        }

        let res=await Api.addTrend(data);


        if (res.status == "200")
        {
            await Notifications.successMsg(res.message);
            history.push('/trends');
        }
        else
        {
            await Notifications.errorMsg(res.message);
        }
        resetForm();
    }

    return (
        <>
            <h3 className='dashboard-head-title'>Add Property</h3>
            <form onSubmit={submitForm}>
                <div className=' load-form'>
                    <div className=' load-input-wrapper'>
                        <div className=' load-input'>
                            <label>Name</label>
                            <input value={name} onChange={(e)=>{setName(e.target.value)}} required type={'text'} placeholder="Enter Name" className='form-input_ input' />
                        </div>
                        <div className=' load-input'>
                            <label>Year 1</label>
                            <input value={year1} onChange={(e)=>{setYear1(e.target.value)}} required type={'number'} placeholder="Enter Year" className='form-input_ input' />
                        </div>
                        <div className=' load-input'>
                            <label>Year 2</label>
                            <input value={year2} onChange={(e)=>{setYear2(e.target.value)}} required type={'number'} placeholder="Enter Year" className='form-input_ input' />
                        </div>
                        <div className=' load-input'>
                            <label>Year 3</label>
                            <input value={year3} onChange={(e)=>{setYear3(e.target.value)}} required type={'number'} placeholder="Enter Year" className='form-input_ input' />
                        </div>
                        <div className=' load-input'>
                            <label>Year 4</label>
                            <input value={year4} onChange={(e)=>{setYear4(e.target.value)}} required type={'number'} placeholder="Enter Year" className='form-input_ input' />
                        </div>
                        <div className=' load-input'>
                            <label>Year 5</label>
                            <input value={year5} onChange={(e)=>{setYear5(e.target.value)}} required type={'number'} placeholder="Enter Year" className='form-input_ input' />
                        </div>
                        <div className=' load-input'>
                            <label>Price 1</label>
                            <input value={price1} onChange={(e)=>{setPrice1(e.target.value)}} required type={'number'} placeholder="Enter Year" className='form-input_ input' />
                        </div>
                        <div className=' load-input'>
                            <label>Price 2</label>
                            <input value={price2} onChange={(e)=>{setPrice2(e.target.value)}} required type={'number'} placeholder="Enter Year" className='form-input_ input' />
                        </div>
                        <div className=' load-input'>
                            <label>Price 3</label>
                            <input value={price3} onChange={(e)=>{setPrice3(e.target.value)}} required type={'number'} placeholder="Enter Year" className='form-input_ input' />
                        </div>
                        <div className=' load-input'>
                            <label>Price 4</label>
                            <input value={price4} onChange={(e)=>{setPrice4(e.target.value)}} required type={'number'} placeholder="Enter Year" className='form-input_ input' />
                        </div>
                        <div className=' load-input'>
                            <label>Price 5</label>
                            <input value={price5} onChange={(e)=>{setPrice5(e.target.value)}} required type={'number'} placeholder="Enter Year" className='form-input_ input' />
                        </div>
                    </div>
                    <div className='form-buttons'>
                        <button type={'submit'} className='button-primary'>Save Changes</button>
                        {/*<button className='button-primary btn-cancel'>Cancel</button>*/}
                    </div>
                </div>
            </form>
        </>
    )
}

export default AddTrend