import React, { useEffect, useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import Api from "../../../../../apis/apis";
import Notifications from "../../../../../notifications/notifications";
import { useHistory } from "react-router-dom";

function AddAds() {
  const history = useHistory();

  const [title, setTitle] = useState('');
  const [type, setType] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [url, setUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [position, setPosition] = useState(0);

  async function submitForm(e) {
    e.preventDefault();

    if (
      !imageUrl ||
      imageUrl === null ||
      imageUrl === "" ||
      title === "" ||
      title === null ||
      type === ""
    ) {
      await Notifications.errorMsg("Enter Fields and images");
      return;
    }

    const formData = new FormData();
    formData.append("image", imageUrl);
    formData.append("title", title);
    formData.append("type", type);
    formData.append("url", url);
    formData.append("position", position);

    setLoading(true);

    let res = await Api.addAdsAction(formData);

    if (res.status == "200") {
      await Notifications.successMsg(res.message);
      history.push("/ads");
    } else {
      await Notifications.errorMsg(res.message);
    }
    setLoading(false);
  }

  const addImage = (e) => {
    const file = e?.target.files[0];
    if (!file) {
      return;
    }
    setImageUrl(file);
  };

  return (
    <>
      <h3 className="dashboard-head-title">Add Ads</h3>
      <form onSubmit={submitForm}>
        <div className=" load-form">
          <div className=" load-input-wrapper">
            <div className=" load-input">
              <label>Title</label>
              <input
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                }}
                required
                type={"text"}
                placeholder="Enter Name"
                className="form-input_ input"
              />
            </div>
          </div>
          <div className=" load-input-wrapper">
            <div className=" load-input">
              <label>Type</label>
              <select
                onChange={(e) => {
                  setType(e.target.value);
                }}
                required
                className="form-input_ input"
              >
                <option value={""}>Select</option>
                <option value={"Best value"}>Best Value</option>
                {/* <option value={"Header"}>For Header</option> */}
                <option value={"Properties Header"}>
                  For Properties Header
                </option>
                <option value={"About Header"}>For About Header</option>
                <option value={"Registration Header"}>
                  For Registration Header
                </option>
                <option value={"Trends Header"}>For Trends Header</option>
                <option value={"Account Header"}>For My Account Header</option>
                <option value={"Add Properties Header"}>
                  For Add Properties Header
                </option>
                <option value={"Properties Detail Header"}>
                  For Properties Detail Header
                </option>
              </select>
            </div>
          </div>
          {type === "Best value" && (
            <div className=" load-input-wrapper">
              <div className=" load-input">
                <label>Position</label>
                <input
                  value={position}
                  onChange={(e) => {
                    setPosition(e.target.value);
                  }}
                  required
                  type={"number"}
                  placeholder="Enter Position"
                  className="form-input_ input"
                />
              </div>
            </div>
          )}
          <div className=" load-input-wrapper">
            <div className=" load-input">
              <label>Link url</label>
              <input
                value={url}
                onChange={(e) => {
                  setUrl(e.target.value);
                }}
                type={"text"}
                placeholder="https://example.com"
                className="form-input_ input"
              />
            </div>
          </div>
          <div className=" load-input-wrapper">
            <div className=" load-input">
              <label>Image / Video (mp4,webm,ogg,mov)</label>
              <input
                className="form-input_ input"
                type="file"
                accept="image/*, video/mp4, video/webm, video/ogg, video/mov"
                onChange={addImage}
              />
            </div>
          </div>
          <div className="form-buttons">
            <button
              type={"submit"}
              className="button-primary"
              disabled={loading}
            >
              {loading && (
                <Spinner
                  style={{ width: "18px", height: "18px" }}
                  animation="border"
                />
              )}{" "}
              Submit
            </button>
          </div>
        </div>
      </form>
    </>
  );
}

export default AddAds;
